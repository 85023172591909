import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {}
    },
    uk: {
        translation: {
            "Sign Up": "Зареєструватись",
            "Name": "Імʼя",
            "Email": "Email",
            "Desired Language": "Мова, яку хочеш вивчати",
            "I agree with ": "Я погоджуюсь з ",
            "Terms&Conditions": "Договором про надання послуг",
            "Sign up to start learning programming:": "Зареєструйся, щоб почати навчатись програмуванню:",
            "Phone": "Телефон",
            "Hey! I'm Vitalii Pavliuk, a programmer and IT teacher. Welcome to my website! Here you can chat with my Digital Twin, powered by AI.":
                "Привіт! Я Віталій Павлюк, програміст та викладач в ІТ. Ласкаво прошу на свій сайт! Тут ти можеш поспілкуватись з моїм ШІ-двійником, який працює на базі штучного інтелекту.",
            "Hello! 👋 I'm Vitalii's Digital Twin, powered by AI. 🤖 Ready to chat about tech, triathlons, and the adventures of life! 🚀💻🏊‍♂️":
                "Привіт! 👋 Я ШІ-двійник Віталія, який працює на базі штучного інтелекту. 🤖 Готовий поговорити про технології, триатлон та пригоди життя! 🚀💻🏊‍♂️",
            "View on Instagram": "Переглянути в Instagram",
            "View on Strava": "Переглянути в Strava",
            "Contact me": "Зв'язатись зі мною",
            "My twin": "Мій двійник",
            "Book me": "Записатись до мене",
            "2024 — now": "2024 — теперішній час",
            "Go to website": "Перейти на сайт",
            "Kyiv Polytechnic Institute": "Київський Політехнічний Інститут",
            "Master Degree in Computer Science": "Магістр комп'ютерних наук",
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;