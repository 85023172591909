import React from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@mui/lab';
import { Paper, Typography, Link, Card } from '@mui/material';
import { Work, VolunteerActivism, School, LinkedIn } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Projects = () => {
    const { t } = useTranslation();
  
    // Structured object for all projects and experiences
    const projects = [
      {
        date: t("2024 — now"),
        title: "Preply",
        role: t("Backend Engineer"),
        link: "https://preply.com/en/?pref=MTI4NDM3OTU=&id=1727537572.923898",
        linkText: t("Go to website"),
        color: "primary",
        icon: <Work />
      },
      {
        date: "2022 — 2024",
        title: "Kavall",
        role: t("Full-Stack Engineer"),
        link: "",
        linkText: "",
        color: "secondary",
        icon: <Work />
      },
      {
        date: "2016 — 2022",
        title: "Universum Global",
        role: t("Software Engineer / Engineering Lead"),
        link: "https://careertest.universumglobal.com",
        linkText: t("Go to website"),
        color: "error",
        icon: <Work />
      },
      {
        date: "2020 — теперішній час",
        title: "BotsPlatform.org",
        role: t("Volunteering projects for local communities"),
        link: "https://botsplatform.org",
        linkText: t("Go to website"),
        color: "success",
        icon: <VolunteerActivism />
      },
      {
        date: "2013 — 2019",
        title: t("Kyiv Polytechnic Institute"),
        role: t("Master Degree in Computer Science"),
        link: "https://kpi.ua/en",
        linkText: t("Go to website"),
        color: "primary",
        icon: <School />
      },
      {
        date: t("LinkedIn"),
        title: t("Check out my LinkedIn"),
        role: "",
        link: "https://www.linkedin.com/in/vitalii-pavliuk",
        linkText: t("Go to website"),
        color: "info",
        icon: <LinkedIn />
      }
    ];
  
    return (
      <Card sx={{ margin: { xs: 1, md: 10 } }}>
        <Typography variant="h4" align="center" sx={{ marginBottom: '30px' }}>
          {t("My projects and career path")}
        </Typography>
  
        <Timeline position="alternate">
          {projects.map((project, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align={index % 2 === 0 ? 'right' : 'left'}
                variant="body2"
                color="text.secondary"
              >
                {project.date}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color={project.color as "primary" | "secondary"}>
                  {project.icon}
                </TimelineDot>
                {index < projects.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Paper elevation={3} sx={{ padding: '10px' }}>
                  <Typography variant="h6" component="span">
                    {project.title}
                  </Typography>
                  <Typography>
                        {project.role !== "" && (
                            <>
                            {project.role} <br />
                            </>
                        )}
                        {project.link !== "" && (
                            <Link href={project.link} target="_blank" rel="noopener">
                            {project.linkText}
                            </Link>
                        )}
                    </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Card>
    );
  };
  
  export default Projects;