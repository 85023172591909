import { Box, Card, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const FordQuote = () => {
    const { t } = useTranslation();

    const quotes = [
        "“Whether you think you can, or you think you can't – you're right.”",
        "“Quality means doing it right when no one is looking.”",
        "“Coming together is a beginning; keeping together is progress; working together is success.”",
        "“Don't find fault, find a remedy.”",
        "“Failure is simply the opportunity to begin again, this time more intelligently.”",
        "“If you always do what you've always done, you'll always get what you've always got.”",
        "“A business that makes nothing but money is a poor business.”",
        "“You can't build a reputation on what you are going to do.”",
        "“The only real mistake is the one from which we learn nothing.”",
    ]

    // quote number depends on date
    const quoteNumber = new Date().getDate() % quotes.length;

    const quote = quotes[quoteNumber];

    return (
        <Card sx={{margin: { xs: 1, md: 10}}}>
            <Box
                sx={{
                padding: '20px',
                boxShadow: 1,
                textAlign: 'center',
                }}
            >
                <Typography
                variant="h4"
                component="p"
                sx={{ fontStyle: 'italic', marginBottom: '10px' }}
                >
                {quote}
                </Typography>
                <Divider variant="middle" sx={{ backgroundColor: '#3498db', margin: '10px 0' }} />
                <Typography variant="subtitle1" component="p" sx={{ fontWeight: 'bold' }}>
                — {t('Henry Ford')}
                </Typography>
            </Box>
      </Card>
    );
  };

export default FordQuote;
