import React, {useEffect, useMemo, useRef} from "react";
import {Avatar, Box, Button, Card, CardHeader, Input, Paper} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import Message from "./Message";

type ChatProps = {
    avatarSrc: string;
    title: string;
    subheader: string;
}

const Chat = ({ avatarSrc, title, subheader }: ChatProps) => {
    const [message, setMessage] = React.useState("");
    const [messages, setMessages] = React.useState<{role: string, content: string}[]>([]);
    const webSocketRef = useRef<WebSocket | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);

    const uid = useMemo(() => Math.random().toString(36).substr(2, 9), []);

    const onClick = () => {
        if (webSocketRef.current) {
            const lastMessage = { role: "user", content: message, uid };

            webSocketRef.current.send(JSON.stringify(lastMessage));
            setMessage("");
            setMessages((prevMessages) => [...prevMessages, lastMessage]);
            setIsLoading(true);
            
        }
    }

    const onKeyDown = () => {console.log("onKeyDown")};

    useEffect(() => {
        // Initialize the WebSocket connection
        const socket = new WebSocket('wss://api.vitalii.tech/ws/chat/');
    
        // Listen for messages
        socket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          setMessages((prevMessages) => [...prevMessages, {
            role: message.role,
            content: message.content
          }]);
          setIsLoading(false);
        };
    
        // Handle socket errors
        socket.onerror = (error) => {
          console.error('WebSocket Error: ', error);
        };

        socket.onopen = () => {
            console.log('WebSocket is open now.');
        };

        socket.onclose = () => {
            console.log('WebSocket is closed now.');
        }

        webSocketRef.current = socket;
    
        // Clean up the connection when the component unmounts
        return () => {
          socket.close();
        };
      }, []);

    return <Card sx={{ margin: { xs: 1, md: 10 }, height: "calc(100vh - 224px)", display: "flex", flexDirection: "column" }}>
        <CardHeader
            avatar={<Avatar src={avatarSrc} />}
            title={title}
            subheader={subheader}
        />
        <Box display="flex" flexDirection="column" flexGrow={1}>
            <Paper elevation={3} sx={{ margin: 5, backgroundColor: "#EEE", flexGrow: 1 }}>
                <Box sx={{ overflow: "scroll", maxHeight: "calc(100vh - 443px)" }}>
                    {messages.map((message, index) => {
                        return <Message key={index} role={message.role} message={message.content} />
                    })}
                    {isLoading && <Message role="assistant" isLoading={true} />}
                </Box>
            </Paper>
            <Box display="flex" justifyContent="flex-end" gap={5} sx={{ margin: 5, marginTop: 0 }}>
                <Input placeholder="Type your message here..." value={message} onChange={e => setMessage(e.target.value)} sx={{ flexGrow: 1 }} onKeyDown={onKeyDown} />
                <Button variant="contained" onClick={onClick} endIcon={<SendIcon />}>
                    Send
                </Button>
            </Box>
        </Box>
    </Card>;
}

export default Chat;
